import React from "react";
import {
  Box,
  Stack,
  Typography,
  Grid,
  Button,
  Card,
  Divider,
} from "@mui/material";
import MainPagePhoto from "./Images/mainPhoto.png";
import { ContactForm } from "./ContactForm";
import References from "./References";
import ShopNow from "./ShopNow";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";

export const MainPage = () => {
  const productsArray = [
    "Sneakers",
    "Yoga Mat",
    "Coffee",
    "Skin Care",
    "DVD / Blu-Ray",
    "Books",
    "Toys",
    "Health Products",
    "Hobbies",
    "Technology",
    "Hand Tools",
    "Outfit",
  ];
  const pageTitle = "CETMART | Home";
  const pageDescription =
    "Explore Diverse Products at Cetmart - Your Source for a Wide Range of Products";
  return (
    <Box sx={{ cursor: "default" }}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>
      <Stack
        spacing={2}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Typography
          color={"#fff"}
          textAlign="center"
          variant="h4"
          fontWeight="bold"
          component={"h1"}
          sx={{ cursor: "default" }}
        >
          Welcome <br></br> Cetmart
        </Typography>
        <Stack spacing={2}>
          <ShopNow title="Shop Now" />
          <Button variant="contained">
            <Link
              style={{ color: "white", textDecoration: "none" }}
              to={"/contact"}
            >
              Contact Now
            </Link>
          </Button>
        </Stack>
      </Stack>
      <Stack boxShadow={10}>
        <img
          className="img-fluid"
          style={{ width: "100%", height: "91vh" }}
          src={MainPagePhoto}
          loading="lazy"
          alt="Home"
        />
      </Stack>
      <Box>
        <Box maxWidth={"60vw"} m={"auto"}>
          <Typography
            marginTop={7}
            fontWeight={"bold"}
            variant="h4"
            textAlign={"center"}
            color={"#1976d2"}
            component={"div"}
          >
            Recognize Us
          </Typography>
          <Divider sx={{ maxWidth: "50%", marginX: "auto", marginTop: 2 }} />
          <Grid container spacing={2} marginTop={5}>
            <Grid item xs={12} md={6}>
              <Box
                mb={1}
                sx={{
                  backgroundColor: "white",
                  borderLeft: "solid 10px #1976d2",
                  boxShadow: 5,
                  padding: 5,
                  borderRadius: 1,
                  marginBottom: 3,
                }}
              >
                <Typography mb={1} variant="h4">
                  About Us
                </Typography>
                <Typography variant="body2">
                  Welcome to our online store that has been providing our
                  customers with pleasant shopping experiences for more than 5
                  years. Working passionately since its inception, Cetmart aims
                  to keep customer satisfaction at the highest level and provide
                  a unique shopping experience with quality products.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  backgroundColor: "white",
                  borderLeft: "solid 10px #1976d2",
                  boxShadow: 5,
                  padding: 5,
                  borderRadius: 1,
                  marginBottom: 3,
                }}
              >
                <Typography mb={1} variant="h4">
                  Who We Are?
                </Typography>
                <Typography variant="body2">
                  Founded in 2019, Cetmart has established a strong foothold in
                  the online shopping world. We started out as a small team and
                  continued to grow day by day based on the trust we built with
                  our customers. Today, we are proud to have reached more than
                  50,000 happy customers.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Grid
          mt={8}
          mb={3}
          container
          textAlign={"center"}
          marginX={"auto"}
          width={"60vw"}
        >
          <Grid>
            <Typography
              sx={{ color: "#1976d2" }}
              variant="h5"
              marginBottom={5}
              fontSize={30}
              fontWeight={"bold"}
              component={"h5"}
            >
              What Kind of Products Can You Buy?
            </Typography>
          </Grid>
          <Grid
            container
            display="flex"
            direction="row"
            spacing={2}
            marginBottom={2}
          >
            {productsArray.map((product, index) => (
              <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                <Card
                  sx={{
                    padding: 1,
                    margin: 1,
                    border: "1px solid #1976d2",
                    color: "#1976d2",
                    cursor: "default",
                    "&:hover": {
                      color: "black",
                    },
                    transition: "0.3s",
                  }}
                >
                  {product}
                </Card>
              </Grid>
            ))}
          </Grid>

          <Grid padding={2} marginRight={"auto"}>
            <ShopNow title="Shop Now" />
          </Grid>
        </Grid>
      </Box>

      <References />
      <ContactForm />
    </Box>
  );
};
