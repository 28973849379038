import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Button, TextField } from "@mui/material";
import emailjs from "@emailjs/browser";
export const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setSubmitting(true);

    const serviceId = "service_4ch7roh";
    const templateId = "template_98mmi99";
    const publicKey = "LkrsnXnWpWvkz4mCb";

    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: "shopforcom",
      message: message,
    };

    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log("Email sent successfully!", response);
        alert("Email sent successfully!");
        setName("");
        setEmail("");
        setMessage("");
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        alert("Error sending email:");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  const [responsive, setResponsive] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 800;

      setResponsive(isMobile);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      marginX={"auto"}
      style={{ width: responsive ? "100%" : "60%", margin: "0 auto" }}
    >
      <Box sx={{ p: 3 }}>
        <Typography color={"primary.dark"} variant="h5" gutterBottom>
          Contact Us
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="name"
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="email"
                type="email"
                label="E-Mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="message"
                label="Your Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                multiline
                rows={4}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={submitting}
              >
                {submitting ? "Sending..." : "Send"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};
