const Links = {
  phoneNumber: "+17809348399",
  whatsappLink: `https://wa.me/+17809348399`,
  instagramLink:
    "https://www.instagram.com/nabtrade2023?igsh=MTZoMGlib3JkZW00eQ== ",
  amazonCanada:
    "https://www.amazon.ca/s?me=A3PCKLYWFWCYNG&marketplaceID=A2EUQ1WTGCTBG2",
  amazonUsa:
    "https://www.amazon.com/s?me=A3PCKLYWFWCYNG&marketplaceID=ATVPDKIKX0DER",
};

export default Links;
