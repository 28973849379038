import React from "react";
import { useState } from "react";
import Links from "./Links";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import StorefrontIcon from "@mui/icons-material/Storefront";
interface ShopNowProps {
  title: string;
  style?: React.CSSProperties; // Optional style object
}
function ShopNow({ title, style }: ShopNowProps) {
  const { amazonCanada, amazonUsa } = Links;
  const [shop, setShop] = useState(false);
  const styles = { ...style };

  return (
    <>
      <Button style={styles} onClick={() => setShop(true)} variant="contained">
        <StorefrontIcon />
        <Divider
          sx={{ marginX: 2, backgroundColor: "white" }}
          orientation="vertical"
          variant="middle"
          flexItem
        />
        {title}
      </Button>
      <Dialog open={shop} onClose={() => setShop(false)}>
        <DialogTitle
          color={"primary"}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginY: "5px",
            cursor: "default",
          }}
        >
          Choose Your Location
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginY: "5px",
              cursor: "default",
            }}
          >
            For a better purchasing experience, please select your country of
            purchase
          </DialogContentText>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginY: "5px",
            }}
          >
            <Button variant="contained" target="_blank" href={amazonCanada}>
              Canada
            </Button>
            <Button variant="contained" target="_blank" href={amazonUsa}>
              USA
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ShopNow;
