import React from "react";
import { Box, Stack, Typography, Card, Grid, CardContent } from "@mui/material";
import ShopNow from "./ShopNow";
import animal from "./Images/animal.jpg";
import skinCare from "./Images/skinCare.jpg";
import sneakers from "./Images/sneakers.jpg";
import health from "./Images/health.jpg";
import coffee from "./Images/coffee.jpg";
import { CardHeader } from "react-bootstrap";
import Helmet from "react-helmet";

const style = {
  padding: "15px",
  width: "150px",
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "15px",
  marginBottom: "15px",
};
function Product() {
  const pageTitle = "CETMART | Products";
  const pageDescription =
    "Check out our wide range of products, buy quality products at the most affordable price and in the fastest way.";
  return (
    <Box my={5} sx={{ cursor: "default" }}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>
      <Stack>
        <Typography
          mb={4}
          variant="h4"
          fontWeight={"bold"}
          textAlign={"center"}
          sx={{ cursor: "default" }}
        >
          Products
          <Typography color={"#1976d2"}>Best Selling Products</Typography>
        </Typography>
      </Stack>
      <Stack
        direction="row"
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ flexWrap: "wrap" }}
      >
        <Stack></Stack>
        <Card sx={{ textAlign: "center", margin: 2 }}>
          <CardContent>
            <CardHeader>Sneakers</CardHeader>
            <img
              style={{
                transition: "all .2s ease-in-out",
                marginRight: "auto",
                marginLeft: "auto",
                display: "block",
                height: "auto",
                maxWidth: "100%",
                marginTop: 10,
                marginBottom: 10,
                borderRadius: "4px",
              }}
              width={"200px"}
              height={"200px"}
              src={sneakers}
              alt=""
            />
          </CardContent>
        </Card>
        <Card sx={{ textAlign: "center", margin: 2 }}>
          <CardContent>
            <CardHeader>Skin Care</CardHeader>
            <img
              style={{
                transition: "all .2s ease-in-out",
                marginRight: "auto",
                marginLeft: "auto",
                display: "block",
                height: "auto",
                maxWidth: "100%",
                marginTop: 10,
                marginBottom: 10,
                borderRadius: "4px",
              }}
              width={"200px"}
              height={"200px"}
              src={skinCare}
              alt=""
            />
          </CardContent>
        </Card>
        <Card sx={{ textAlign: "center", margin: 2 }}>
          <CardContent>
            <CardHeader>Health Products</CardHeader>
            <img
              style={{
                transition: "all .2s ease-in-out",
                marginRight: "auto",
                marginLeft: "auto",
                display: "block",
                height: "auto",
                maxWidth: "100%",
                marginTop: 10,
                marginBottom: 10,
                borderRadius: "4px",
              }}
              width={"200px"}
              height={"200px"}
              src={health}
              alt=""
            />
          </CardContent>
        </Card>
        <Card sx={{ textAlign: "center", margin: 2 }}>
          <CardContent>
            <CardHeader>Dog & Cat Food</CardHeader>
            <img
              style={{
                transition: "all .2s ease-in-out",
                marginRight: "auto",
                marginLeft: "auto",
                display: "block",
                height: "auto",
                maxWidth: "100%",
                marginTop: 10,
                marginBottom: 10,
                borderRadius: "4px",
              }}
              width={"200px"}
              height={"200px"}
              src={animal}
              alt=""
            />
          </CardContent>
        </Card>
        <Card sx={{ textAlign: "center", margin: 2 }}>
          <CardContent>
            <CardHeader>Coffee</CardHeader>
            <img
              style={{
                transition: "all .2s ease-in-out",
                marginRight: "auto",
                marginLeft: "auto",
                display: "block",
                height: "auto",
                maxWidth: "100%",
                marginTop: 10,
                marginBottom: 10,
                borderRadius: "4px",
              }}
              width={"200px"}
              height={"200px"}
              src={coffee}
              alt=""
            />
          </CardContent>
        </Card>
      </Stack>
      <Stack spacing={2}>
        <Stack marginX={"auto"}>
          <Typography textAlign={"center"} variant="h6">
            Click Now to Discover Many More Products
          </Typography>

          <ShopNow title="REVIEW" style={style} />
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <Card>
          <Grid item xs={12} lg={3} textAlign="center">
            <Typography variant="h5">Year</Typography>
            <Typography variant="h3" fontWeight="bold" color="#1976d2">
              +5
            </Typography>
          </Grid>
        </Card>
        <Card>
          <Grid item xs={12} lg={3} textAlign="center">
            <Typography variant="h5">Total Customer</Typography>
            <Typography variant="h3" fontWeight="bold" color="#1976d2">
              +50.000
            </Typography>
          </Grid>
        </Card>
        <Card>
          <Grid item xs={12} lg={3} textAlign="center">
            <Typography variant="h5">Total Sales</Typography>
            <Typography variant="h3" fontWeight="bold" color="#1976d2">
              +70.000
            </Typography>
          </Grid>
        </Card>
        <Grid container direction="row" justifyContent="center"></Grid>
      </Stack>
    </Box>
  );
}

export default Product;
