import React, { useEffect, useState } from "react";
import { ContactForm } from "./ContactForm";
import {
  Box,
  Stack,
  Typography,
  Divider,
  Card,
  Grid,
  IconButton,
} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailIcon from "@mui/icons-material/Mail";
import Links from "./Links";
import Helmet from "react-helmet";

export const Contact = () => {
  const { phoneNumber, whatsappLink, instagramLink } = Links;
  const pageTitle = "CETMART | Contact";
  const pageDescription =
    "You can access us 24/7 through the communication channels below, you can use the options below or you can visit our store at the address below... Cetmart";
  const [responsive, setResponsive] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 800;

      setResponsive(isMobile);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Box mt={5} sx={{ cursor: "default" }}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>
      <Typography
        sx={{ cursor: "default" }}
        fontWeight={"bold"}
        textAlign={"center"}
        variant="h4"
      >
        Contact Us
      </Typography>
      <Divider sx={{ width: "50%", marginX: "auto", marginTop: 1 }} />
      <Typography variant="body2" mt={3} textAlign={"center"}>
        You can access us 24/7 through the communication channels below, you can
        use the options below or you can visit our store at the address below...
      </Typography>
      <Stack
        direction="row"
        spacing={5}
        my={2}
        justifyContent="center"
        alignItems="center"
      >
        <IconButton className="contactBtn" href={instagramLink}>
          <InstagramIcon className="contactIconBtns" />
        </IconButton>
        <IconButton className="contactBtn" href={whatsappLink}>
          <WhatsAppIcon className="contactIconBtns" />
        </IconButton>
      </Stack>
      <Divider sx={{ width: "50%", marginX: "auto", marginY: 1 }} />
      <Stack
        direction="row"
        alignItems="center"
        width={"60vw"}
        marginX={"auto"}
      >
        <Grid
          my={3}
          xs={12}
          container
          direction="row"
          alignItems="center"
          textAlign={"center"}
          justifyContent="center"
        >
          <Grid xs={10} sm={10} lg={2}>
            <IconButton disabled className="contactBtn" href="#">
              <LocationOnIcon className="contactIconBtns" />
            </IconButton>
          </Grid>
          <Grid item xs={12} lg={4} my={2}>
            <Typography variant="body2">
              47-1128 156 Street NW Edmonton/Alberta/Canada<br></br> Phone:{" "}
              {phoneNumber}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <Card
              sx={{
                width: responsive ? "100%" : "60%",
                margin: "0 auto",
                display: responsive ? "none" : "block",
              }}
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2376.4926096336285!2d-113.59136409999999!3d53.4417799!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53a01e2b59f7c49b%3A0x7d301e9b5209d11f!2s1128%20156%20St%20NW%20Unit%2047%2C%20Edmonton%2C%20AB%20T6R%200C9%2C%20Canada!5e0!3m2!1sen!2str!4v1715081826351!5m2!1sen!2str"
                width="100%"
                height="350px"
              ></iframe>
            </Card>
          </Grid>
        </Grid>
      </Stack>
      <Stack
        sx={{
          width: responsive ? "90%" : "60%",

          margin: "0 auto",
          display: responsive ? "block" : "none",
        }}
        mx={"auto"}
      >
        <Card>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2376.4926096336285!2d-113.59136409999999!3d53.4417799!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53a01e2b59f7c49b%3A0x7d301e9b5209d11f!2s1128%20156%20St%20NW%20Unit%2047%2C%20Edmonton%2C%20AB%20T6R%200C9%2C%20Canada!5e0!3m2!1sen!2str!4v1715081826351!5m2!1sen!2str"
            width="100%"
            height="350px"
          ></iframe>
        </Card>
      </Stack>
      <Stack direction="row" justifyContent="center" alignItems="center">
        <IconButton disabled className="contactBtn" href="#">
          <MailIcon className="contactIconBtns" />
        </IconButton>
      </Stack>

      <ContactForm />
    </Box>
  );
};
export default Contact;
