import React, { useState, useEffect } from "react";
import StarIcon from "@mui/icons-material/Star";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Box,
  Grid,
} from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

// Referans verileri
const referencesData = [
  {
    name: "Linda",
    position: "Timely delivery. All good thanks",
  },
  {
    name: "Cynthia",
    position:
      "Love these smart plugs. They are easy to set up and I have several in the house I use on timers. This is my second pack!",
  },
  {
    name: "Rosiris",
    position: "Llego el producto exacto, en menos tiempo de lo previsto",
  },
];

const References = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? referencesData.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === referencesData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const currentReference = referencesData[currentIndex];

  const [responsive, setResponsive] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 768;

      setResponsive(isMobile);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      style={{ width: responsive ? "100%" : "60vw", margin: "0 auto" }}
      padding={2}
      marginX={"auto"}
    >
      <Card sx={{ textAlign: "center" }}>
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={2}>
              <IconButton onClick={handlePrevious}>
                <ArrowBackIos />
              </IconButton>
            </Grid>
            <Grid item xs={8}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Box ml={2}>
                  <Typography variant="h6">{currentReference.name}</Typography>
                  <Typography my={1} variant="body2" color="textSecondary">
                    {currentReference.position}
                  </Typography>
                  <Box
                    display="flex"
                    flexDirection={"row"}
                    justifyContent={"center"}
                    alignItems="center"
                    textAlign={"center"}
                  >
                    {[...Array(5)].map((_, index) => (
                      <StarIcon
                        key={index}
                        sx={{
                          color: index < 5 ? "#FFA41C" : "gray",
                        }}
                      />
                    ))}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <IconButton onClick={handleNext}>
                <ArrowForwardIos />
              </IconButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default References;
