import React from "react";
import { Link } from "react-router-dom";

import {
  Box,
  Stack,
  Typography,
  Divider,
  Grid,
  CardContent,
  IconButton,
} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import affordablePrice from "./Images/affordablePrice.jpg";
import fastShipping from "./Images/fastShipping.jpg";
import reliability from "./Images/reliability.jpg";
import quality from "./Images/quality.jpg";
import ShopNow from "./ShopNow";
import Links from "./Links";
import Helmet from "react-helmet";

function AboutUs() {
  const { instagramLink, whatsappLink } = Links;
  const pageTitle = "CETMART | About Us";
  const pageDescription =
    "Welcome to Cetmart, your shop for quality products. Learn more about our affordable prices, fast shipping and reliability.";

  return (
    <Box my={5} sx={{ cursor: "default" }}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>
      <Typography
        sx={{ cursor: "default" }}
        my={2}
        textAlign={"center"}
        fontWeight={"bold"}
        variant="h4"
      >
        About Us
      </Typography>
      <Divider sx={{ width: "50%", marginX: "auto" }} />
      <Typography
        width={"60%"}
        mx={"auto"}
        textAlign={"center"}
        variant="body2"
        my={1}
      >
        Welcome to our online store that has been providing our customers with
        pleasant shopping experiences for more than 5 years. Working
        passionately since its inception, Cetmart aims to keep customer
        satisfaction at the highest level and provide a unique shopping
        experience with quality products.
      </Typography>
      <Grid
        width={"60%"}
        container
        textAlign={"center"}
        mx={"auto"}
        my={4}
        xs={12}
        direction={"row"}
      >
        <Grid className="aboutCard" item p={1} xs={12} sm={6} md={6} lg={3}>
          <Grid>
            <img
              style={{
                display: "block",
                height: "auto",
                maxWidth: "100%",

                borderRadius: "5%",
              }}
              width={"100%"}
              height={"100%"}
              src={affordablePrice}
              alt=""
            />
          </Grid>
          <CardContent sx={{ cursor: "default" }}>
            <Typography color={"primary.dark"} fontWeight={"bold"}>
              AFFORDABLE PRICE
            </Typography>
          </CardContent>
        </Grid>
        <Grid className="aboutCard" item p={1} xs={12} sm={6} md={6} lg={3}>
          <Grid>
            <img
              style={{
                display: "block",
                height: "auto",
                maxWidth: "100%",

                borderRadius: "5%",
              }}
              width={"100%"}
              height={"100%"}
              src={fastShipping}
              alt=""
            />
          </Grid>
          <CardContent sx={{ cursor: "default" }}>
            <Typography color={"primary.dark"} fontWeight={"bold"}>
              FAST SHIPPING
            </Typography>
          </CardContent>
        </Grid>
        <Grid className="aboutCard" item p={1} xs={12} sm={6} md={6} lg={3}>
          <Grid>
            <img
              style={{
                transition: "all .2s ease-in-out",

                display: "block",
                height: "auto",
                maxWidth: "100%",

                borderRadius: "5%",
              }}
              width={"100%"}
              height={"100%"}
              src={reliability}
              alt=""
            />
          </Grid>
          <CardContent sx={{ cursor: "default" }}>
            <Typography color={"primary.dark"} fontWeight={"bold"}>
              RELIABILITY
            </Typography>
          </CardContent>
        </Grid>
        <Grid className="aboutCard" item p={1} xs={12} sm={6} md={6} lg={3}>
          <Grid>
            <img
              style={{
                transition: "all .2s ease-in-out",

                display: "block",
                height: "auto",
                maxWidth: "100%",

                borderRadius: "5%",
              }}
              width={"100%"}
              height={"100%"}
              src={quality}
              alt=""
            />
          </Grid>
          <CardContent sx={{ cursor: "default" }}>
            <Typography color={"primary.dark"} fontWeight={"bold"}>
              QUALITY
            </Typography>
          </CardContent>
        </Grid>
      </Grid>
      <Stack>
        <Typography
          width={"60%"}
          mx={"auto"}
          textAlign={"center"}
          variant="body2"
          my={1}
        >
          Founded in 2019, Cetmart has established a strong foothold in the
          online shopping world. We started out as a small team and continued to
          grow day by day based on the trust we built with our customers. Today,
          we are proud to have reached more than 50,000 happy customers.
        </Typography>
        <Divider sx={{ width: "50%", marginX: "auto" }} />
      </Stack>
      <Grid
        width={"60%"}
        mx={"auto"}
        direction={"row"}
        textAlign={"center"}
        justifyContent={"center"}
        alignItems={"center"}
        container
        xs={12}
      >
        <Grid my={4} xs={12} sm={12} md={6} lg={6}>
          <Typography
            variant="h5"
            fontStyle={"italic"}
            textAlign={"center"}
            color={"#1976d2"}
            fontWeight={500}
          >
            Follow <br /> Us
          </Typography>
        </Grid>
        <Grid
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          xs={12}
          sm={12}
          md={6}
          lg={6}
        >
          <Grid xs={12}>
            <Grid xs={12} sm={12} md={6} lg={6}>
              <IconButton target="blank" href={instagramLink}>
                <InstagramIcon />
              </IconButton>
            </Grid>
            <Grid xs={12} sm={12} md={6} lg={6}>
              <IconButton target="blank" href={whatsappLink}>
                <WhatsAppIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid xs={12}>
            <Grid xs={12} sm={12} md={6} lg={6}>
              <ShopNow title="Amazon" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ width: "50%", marginX: "auto" }} />
      <Stack></Stack>
      <Stack>
        <Typography
          my={2}
          textAlign={"center"}
          fontStyle={"italic"}
          variant="h5"
          color={"#1976d2"}
          fontWeight={500}
        >
          Contact Us
        </Typography>
        <Typography marginX={"auto"}>
          <Link
            to={"/contact"}
            style={{
              textDecoration: "none",
              color: "inherit",
            }}
          >
            Click to get in touch
          </Link>
        </Typography>
      </Stack>
    </Box>
  );
}

export default AboutUs;
