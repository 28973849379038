import React, { useState } from "react";
import { Link } from "react-router-dom";
import burgerLogo from "./Images/burgerLogo.png";
import { Footer } from "./Footer";
import {
  AppBar,
  Toolbar,
  Box,
  Stack,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import "./Style/Navbar.css";

export const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <Box>
      <AppBar
        sx={{
          my: "auto",
          backgroundColor: "black",
          paddingY: 1,
          display: { xs: "none", md: "block" }, // Masaüstü için görünürlük ayarı
        }}
        className="appbar"
        position="static"
      >
        <Toolbar>
          <Link
            to={"/"}
            style={{ textDecoration: "none", color: "inherit" }}
            className="navbarBtn"
          >
            <b>CETMART</b>
          </Link>

          <Stack direction="row" spacing={2} sx={{ marginLeft: "auto" }}>
            <Link
              to={"/"}
              style={{ textDecoration: "none", color: "inherit" }}
              className="navbarBtn"
            >
              Home
            </Link>
            <Link
              to={"/product"}
              style={{ textDecoration: "none", color: "inherit" }}
              className="navbarBtn"
            >
              Products
            </Link>
            <Link
              to={"/about"}
              style={{ textDecoration: "none", color: "inherit" }}
              className="navbarBtn"
            >
              About Us
            </Link>
            <Link
              to={"/contact"}
              style={{ textDecoration: "none", color: "inherit" }}
              className="navbarBtn"
            >
              Contact
            </Link>
          </Stack>
        </Toolbar>
      </AppBar>

      {/* Burger Menu for Mobile */}
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <AppBar
          sx={{
            backgroundColor: "black",
            paddingY: 1,
            display: "flex",
            justifyContent: "space-between",
          }}
          position="static"
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleMobileMenu}
            >
              <MenuIcon />
            </IconButton>
            <Link
              to={"/"}
              style={{ textDecoration: "none", color: "inherit" }}
              className="navbarBtn"
            >
              <b>CETMART</b>
            </Link>
          </Toolbar>
        </AppBar>

        <Drawer
          anchor="left"
          open={isMobileMenuOpen}
          onClose={toggleMobileMenu}
        >
          <Box
            sx={{
              width: 250,
              padding: "20px",
            }}
          >
            <List>
              <>
                <img
                  loading="lazy"
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                  }}
                  src={burgerLogo}
                />
              </>

              <Link
                style={{ textDecoration: "none" }}
                onClick={toggleMobileMenu}
                to={"/"}
              >
                <ListItemText className="burgerBtn" primary="Home" />
              </Link>

              <Link
                style={{ textDecoration: "none" }}
                onClick={toggleMobileMenu}
                to={"/product"}
              >
                <ListItemText className="burgerBtn" primary="Products" />
              </Link>

              <Link
                style={{ textDecoration: "none" }}
                onClick={toggleMobileMenu}
                to={"/about"}
              >
                <ListItemText className="burgerBtn" primary="About Us" />
              </Link>

              <Link
                style={{ textDecoration: "none" }}
                onClick={toggleMobileMenu}
                to={"/contact"}
              >
                <ListItemText className="burgerBtn" primary="Contact" />
              </Link>
            </List>
          </Box>
        </Drawer>
      </Box>
    </Box>
  );
};
