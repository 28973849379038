import React from "react";
import {
  Box,
  Stack,
  Link,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import StorefrontIcon from "@mui/icons-material/Storefront";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useState } from "react";

import Links from "./Links";
export const Footer = () => {
  const { phoneNumber, whatsappLink, instagramLink } = Links;
  const [shop, setShop] = useState(false);
  const year = new Date().getFullYear();

  return (
    <Box
      boxShadow={10}
      sx={{
        cursor: "default",
        color: "white",
        bgcolor: "#0d0d0d",
        textAlign: "center",
        p: 2,
      }}
    >
      <Stack
        spacing={2}
        direction="row"
        justifyContent={"center"}
        marginBottom={2}
      >
        <Button target="blank" href={instagramLink}>
          <InstagramIcon sx={{ fontSize: 36, color: "white" }} />
        </Button>
        <Button target="blank" href={whatsappLink}>
          <WhatsAppIcon sx={{ fontSize: 36, color: "white" }} />
        </Button>
        <Stack>
          <Button onClick={() => setShop(true)}>
            <StorefrontIcon sx={{ fontSize: 36, color: "white" }} />
          </Button>
          <Dialog open={shop} onClose={() => setShop(false)}>
            <DialogTitle
              color={"primary"}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginY: "5px",
              }}
            >
              Choose Your Location
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginY: "5px",
                }}
              >
                For a better purchasing experience, please select your country
                of purchase
              </DialogContentText>
              <DialogActions
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginY: "5px",
                }}
              >
                <Button variant="contained" href="https://www.amazon.ca/">
                  Canada
                </Button>
                <Button variant="contained" href="https://www.amazon.com/">
                  USA
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </Stack>
      </Stack>
      <Stack margin={2}>
        <Typography marginBottom={1} variant="body2">
          <Link style={{ textDecoration: "none", color: "white" }} href="/">
            Home
          </Link>{" "}
          |{" "}
          <Link
            style={{ textDecoration: "none", color: "white" }}
            href="/product"
          >
            Product
          </Link>{" "}
          |{" "}
          <Link
            style={{ textDecoration: "none", color: "white" }}
            href="/about"
          >
            About Us
          </Link>{" "}
          |{" "}
          <Link
            style={{ textDecoration: "none", color: "white" }}
            href="/contact"
          >
            Contact
          </Link>
        </Typography>
        <Typography variant="body2">
          47-1128 156 Street NW Edmonton/Alberta/Canada {""} Phone:{" "}
          {phoneNumber}
        </Typography>
      </Stack>

      <Stack>
        <Typography variant="body2">
          &copy; {year} Cetmart. All Rights Reserved.
        </Typography>
        <Typography
          mt={1}
          variant="body2"
          fontStyle={"italic"}
          fontSize={"12px"}
        >
          Designed by{" "}
          <Link
            sx={{
              color: "white",
              transition: "color .3s",
              "&:hover": {
                color: "#1976d2",
              },
            }}
            href="https://www.enesatacan.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Enes Atacan
          </Link>
        </Typography>
      </Stack>
    </Box>
  );
};
